import * as d3 from "d3";
import type { Component } from "../component";

require("./style.css");

export type StarryNight = {
  width: number;
  height: number;
  getWorker?: typeof getWorker;
};

export const createStarryNight: Component<Partial<StarryNight>> = (datum, previous) => {
  let width = datum.width ? Math.floor(datum.width) : 0;
  let height = datum.height ? Math.floor(datum.height) : 0;

  const selection = createFixedStarryNight({
    width,
    height,
    getWorker: datum.getWorker
  }, previous) as ReturnType<Component<Partial<StarryNight>>>;

  if (typeof datum.width === "number" && typeof datum.height === "number") {
    return selection;
  }

  selection
    .classed("starry-night--full-width", () => typeof datum.width === "undefined")
    .classed("starry-night--full-height", () => typeof datum.height === "undefined");

  const render = () => {
    const node = selection.node() || undefined;
    const rect = node?.getBoundingClientRect();
    const newWidth = typeof datum.width === "number" ? datum.width : rect?.width;
    const newHeight = typeof datum.height === "number" ? datum.height : rect?.height;

    createFixedStarryNight({
      width: Math.floor(newWidth || 0),
      height: Math.floor(newHeight || 0),
      getWorker: datum.getWorker,
    }, node);
  };

  requestAnimationFrame(render);
  window.addEventListener("resize", render);

  return selection;
};

let imageData: ImageData | undefined;
export const createFixedStarryNight: Component<StarryNight> = (datum, previous) => {
  const container = previous ? d3.select<HTMLDivElement, StarryNight>(previous) : createEmptyStarryNight(datum);
  const oldDatum = container.datum();

  function updateCanvas(this: HTMLCanvasElement) {
    const context = this.getContext("2d");
    if (context && imageData) {
      context.putImageData(imageData, 0, 0);
      d3.select(this)
        .classed("starry-night--visible", true);
    }
  }

  const canvasSelection = container
    .select<HTMLCanvasElement>("canvas")
    .attr("width", datum.width)
    .attr("height", datum.height)
    .each(updateCanvas);

  /*
   * It’s just a nightmare to get web workers to work with Storybook,
   * so we accept a dummy worker factory as an attribute.
   */
  const worker = (datum.getWorker || getWorker)((event) => {
    imageData = event.data;
    canvasSelection.each(updateCanvas);
  });

  if (oldDatum.width !== datum.width || oldDatum.height !== datum.height) {
    worker.postMessage({
      width: datum.width,
      height: datum.height,
    });
  }

  return container;
};

let cachedWorker: Worker | undefined;
export function getWorker(onmessage: (event: any) => void): {
  readonly postMessage: (event: any) => void
} {
  if (typeof Worker === "function") {
    if (!cachedWorker) {
      cachedWorker = new Worker("./worker.ts");
      cachedWorker.onmessage = onmessage;
    }
    return cachedWorker;
  } else {
    return {
      postMessage: () => {
        console.warn("Web Workers are not available on this browser");
      },
    };
  }
}

function createEmptyStarryNight(datum: StarryNight) {
  const container = d3.create("div")
    .classed("starry-night", true)
    .datum(datum);

  container.append("canvas")
    .classed("starry-night__canvas", true)
    .attr("width", datum.width)
    .attr("height", datum.height);

  return container;
}
