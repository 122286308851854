import * as d3 from "d3";
import type { GenericComponent } from "../component";

require("./style.css");

export const createCard: GenericComponent = (datum, previous) => {
  const selection = previous
    ? d3.select<HTMLDivElement, Element>(previous).datum(datum)
    : d3.create("div").classed("card", true).datum(datum);

  return selection;
};
